import Axios from "axios";
import {REPORT_BUG_LVL, REPORT_INITIATOR} from "Cheops/constants";


const VERSION = CONFIG.Version ? `/${CONFIG.Version}` : "";

/*
    в cytoscape.js присутствует баг.
    если на мобильном устройстве выделить объект на карте и сразу масштабировать
    то возникает одна из перечисленных ошибок с 'emit'.
    разработчик cytoscape на момент релиза 3.12.1 не устранил проблему.
    мы не пишем ошибку в /bug
 */
let excludeErrorStack = [
    "processRandomSelector",
    "cadesplugin",
    "TypeError: Unable to get property 'emit' of undefined",
    "TypeError: Cannot read property 'emit' of undefined",
    "TypeError: undefined is not an object (evaluating 'R.emit')",
    "Unable to set property 'lastThreeTouch' of undefined or null reference",
    "Failed to execute 'drawImage' on 'CanvasRenderingContext2D':",
    "TypeError: null is not an object (evaluating 'z.touchData.startPosition[0]')",
    "TypeError: null is not an object (evaluating 'z.touchData.startPosition[E]')",
    "TypeError: null is not an object (evaluating 's.scale')",
    "TypeError: null is not an object (evaluating 'v.context.setTransform')",
    "NS_ERROR_NOT_AVAILABLE",
    "TypeError: R is undefined",
    "TypeError: Cannot assign to read only property 'ethereum' of object '#<Window>'",
    "Uncaught TypeError: Cannot read property 'call' of undefined", // TODO Achtung! this is awful! need to find out
];


let excludeUserAgent = ['YaSearchBrowser', 'YandexSearchBrowser'];

let iPhoneUA = 'iPhone';
let miuiUA   = 'XiaoMi/MiuiBrowser';

let reportedErrors = [];

window.addEventListener('error', (event) => {

    setTimeout(async () => {

        const sectionIndex = document.querySelector('section#index');

        if (sectionIndex && !sectionIndex.innerHTML) {

            let new_html = await Axios.get(`/600.html`);
            document.open();
            document.write(new_html.data);
            document.close();

            window.addEventListener('hashchange', () => {

                location.reload();

            }, false);

        }

    }, 500);

    const excludeIndex = excludeErrorStack.findIndex((item) => event.message.indexOf(item) >= 0);
    const excludeBrowserIndex = excludeUserAgent.findIndex((item) => window.navigator.userAgent.indexOf(item) >= 0);

    if ( event.message === "Script error." ) {
        return;
    }

    const scripts = document.getElementsByTagName('script');

    let isKnownScript = false;

    for (let script of scripts) {

        if (script.src === event.filename) {

            isKnownScript = true;
            break;

        }

    }

    if (
        !isKnownScript
        || CONFIG.Mode === "development"
        || excludeIndex !== -1
        || excludeBrowserIndex !== -1
        || event.filename.includes('vendors~main.js')
        || event.filename.includes('inj_js')
        || event.filename.includes('lib/mathjax')
    ) {

        return;

    }

    let errorText = `${event.filename}:${event.lineno} ${event.message}`;

    if (reportedErrors.indexOf(errorText) !== -1) {

        return;

    }

    let url = null;

    if (CONFIG.Instance === 'Noo') {

        url = `${CONFIG.Api.noopolis.url + VERSION}/bug`;

    }

    let headers = {
        'content-type': 'application/json',
        Accept: 'application/json',
    };

    let authToken = localStorage.getItem('auth_token');
    let params = {};

    if (CONFIG.Instance === 'lab' && authToken) {

        url = `${CONFIG.Api.cheopsLaboratory.url + VERSION}/bug`;
        headers.Authorization = `Bearer ${authToken}`;

    }

    if (CONFIG.Instance === 'smt') {

        if (!window.bugReportToken) {

            return;

        }

        url = `/smt-portal/bug`;
        params = {
            auth_token: window.bugReportToken,
        };

    }

    if (!url) {

        return;

    }

    Axios({
        method: "POST",
        url,
        headers,
        data: {
            error: errorText,
            level: REPORT_BUG_LVL.ERROR,
            meta: {
                VERSION,
                stack:    event?.error?.stack,
                response: event?.error?.response,
                url:      location.href,
            },
            initiator: REPORT_INITIATOR.FRONT,
        },
        params,
    });

});
